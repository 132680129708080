import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';

const FilterComponent = React.lazy(() => import('./components/FilterComponent'));

const $cont = document.querySelector('.TJS_ReactFilter_Cont');
const root = ReactDOM.createRoot($cont);
const params = JSON.parse($cont.dataset.package);

if (params.shortmode) {
	root.render(
		<React.StrictMode>
			<div style={{
				background: '#FFF',
				padding: 15,
				paddingTop: 1
			}}>
				<Suspense fallback={<>Загрузка...</>}>
					<FilterComponent
						preload={params.preset}
						shortmode={params.shortmode}
					/>
				</Suspense>
			</div>
		</React.StrictMode>
	);
} else {
	root.render(
		<React.StrictMode>
			<Suspense fallback={<>Загрузка...</>}>
				<FilterComponent preload={params.preset}/>
			</Suspense>
		</React.StrictMode>
	);
}


